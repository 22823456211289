@tailwind base;
@tailwind components;
@tailwind utilities;

/* You can add global styles to this file, and also import other style files */
@import '../../../node_modules/ng-zorro-antd/style/index.min.css';
@import '../../../node_modules/ng-zorro-antd/button/style/index.min.css';
@import '../../../node_modules/ng-zorro-antd/input/style/index.min.css';
@import '../../../node_modules/ng-zorro-antd/form/style/index.min.css';
@import '../../../node_modules/ng-zorro-antd/tag/style/index.min.css';
@import '../../../node_modules/ng-zorro-antd/image/style/index.min.css';
@import '../../../node_modules/ng-zorro-antd/radio/style/index.min.css';
@import '../../../node_modules/ng-zorro-antd/divider/style/index.min.css';
@import '../../../node_modules/ng-zorro-antd/progress/style/index.min.css';
@import '../../../node_modules/ng-zorro-antd/skeleton/style/index.min.css';
@import '../../../node_modules/ng-zorro-antd/alert/style/index.min.css';
@import '../../../node_modules/ng-zorro-antd/modal/style/index.min.css';
@import '~quill/dist/quill.snow.css';
@import 'custom/';

@font-face {
  font-family: 'Lab-Black';
  src: url(./assets/fonts/LabGrotesque-Black.woff2) format('woff2');
}
@font-face {
  font-family: 'Lab-Regular';
  src: url(./assets/fonts/LabGrotesque-Regular.woff2) format('woff2');
}
@font-face {
  font-family: 'Lab-Bold';
  src: url(./assets/fonts/LabGrotesque-Bold.woff2) format('woff2');
}
@font-face {
  font-family: 'Lab-Medium';
  src: url(./assets/fonts/LabGrotesque-Medium.woff2) format('woff2');
}
@font-face {
  font-family: 'Inter-Regular';
  src: url(./assets/fonts/Inter-Regular.woff2) format('woff2');
}
@font-face {
  font-family: 'Inter-SemiBold';
  src: url(./assets/fonts/Inter-SemiBold.woff2) format('woff2');
}

* {
  color: inherit;
  margin: 0;
}
html,
body {
  margin: 0;
  padding: 0;
  scroll-behavior: auto;
  -webkit-overflow-scrolling: touch;
  line-height: 1;
  @apply text-black-base;
  @apply font-inter-regular;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-lab-medium;
  @apply text-black;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 32px;
}

h3 {
  font-size: 28px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 20px;
}
